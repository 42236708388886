.user-progress {
    padding: 15px 20px;
    background-color: $variant-secondary-light-blue;
    @include box-shadow(1px 4px 50px 0px rgba(0, 0, 0, 0.15));
    border: none;
    border-radius: 0px;
    text-align: left;

    .metric-row {
        padding-left: 7px;
        padding-right: 7px;

        .metric {
            border: 1px solid $variant-secondary-dark-blue;
            border-radius: 0px;
            width: 100%;
            min-height: 80px;
            padding-top: 0px;
            padding-bottom: 5px;
            margin: 0 8px;
            @include respond-to(screen-md){ margin: 0 2px; }
            @include respond-to(screen-sm){ margin: 4px 4px; }
            @include respond-to(screen-below-sm){ margin: 4px 0px; }


            .img-col {
                padding-left: 4px;
                padding-right: 10px;
            }

            .metric-title {
                text-transform: uppercase;
                padding-top: 12px;
                @include font-styles(
                    $variant-text-color,
                    $font-size-base-default,
                    $font-weight-medium-default,
                    $line-height-h3-default
                );
                @include respond-to(screen-md){
                    font-size: $font-size-small-default;
                }

            }

            .metric-value {
                font-weight: bold;
            }

            .leaderboard-link-wrapper {
                font-weight: normal;
                a {
                    color: #036A6D;
                    font-weight: $font-weight-bold-default;
                    text-decoration: none;
                }
            }

            .battles-won { color: $variant-primary-green; }
            .battles-lost { color: $variant-primary-red; }
            .battles-tie { color: $variant-primary-orange; }
        }

        .progress-level {
        }
    }

    .progressbar-section {
        .progressbar-title {
            text-align: center;
            @include font-styles(
                $variant-text-color,
                $font-size-base-default,
                $font-weight-bold-default,
                18px
            );
            text-transform: uppercase;
            padding: 20px 0px 0px 0px;
        }

        .level-names {
            @include font-styles($variant-text-color, $font-size-small-default, $font-weight-bold-default);
            margin-bottom: 5px;
            min-height: 16.8px;     // placeholding for loading

            .level-next { text-align: right; }
        }
    }
}
