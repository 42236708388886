// These are default colors
$variant-secondary-light-blue: #E4EAF0;         // header, panel and item background
$variant-secondary-dark-blue: #023449;          // some borders, headings
$variant-dark-blue-login: #064EA1;              // login headings
$variant-text-color: #303030;                   // regular body text
$variant-navbar-text-color: #023449;            // navbar links
$variant-footer: #303030;                       // footer background
$variant-primary-dark-blue: #023449;            // backgrounds, headings
$variant-secondary-future-darkblue: #20206B;    // login page gradient start
$variant-table-stripes: #FFFFFF;                // table stripes in leaderboards
$variant-primary-green: #017632;                // user-progress->battles->won, navbar user-points
$variant-primary-orange: #F2A850;               // user-progress->battles->tie
$variant-primary-red: #ED1C24;                  // user-progress->battles->lost, navbar logout icon, battle lost modal
$variant-primary-green-light: #4EBC8D;          // battle win modal

$variant-progressbar-bg: #A3B5C9;               // progressbar background
$variant-progressbar-fg: $variant-primary-green-light; // progressbar foreground,

$brand-8: $variant-secondary-dark-blue;         // item-dashboard: points, focus, focus row

$text-color-default: $variant-text-color;
$text-color: $text-color-default; // bootstrap var. Cannot be really overridden by area
$text-color-lighter: $variant-text-color;
$black: #000000;
$white: #ffffff;
$danger-bg: #ffe2ec;    // not from design // @todo: adjust
$success-bg: #ccffbd;   // not from design // @todo: adjust
$gray: #A3B5C9;         // not from design, but same as progressbar-bg
$variant-input-border: #7591AB;

// Brands
$brand-primary: $variant-secondary-dark-blue; // bootstrap var

// Typography
// From design in Figma:
//- footer 14 / 400 / 17 / #FFFFFF
//- button 16 / 500 / 20
//- body-text 16 / 400 / 26 / #303030
//- menu-item 18 / 500 / 22
//- h3-heading 18 / 700 / 27 / #023449
//- subheading 22 / 500 / 34
//- h2-heading 32 / 700 / 40 / #023449/#FFFFFF
//- h1-heading 36 / 700 / 45
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/"; // bootstrap var
$font-family-base: "Inter", sans-serif; // bootstrap var

$font-size-smallest-default: 14px;
$font-size-smaller-default: 14px;
$font-size-small-default: 14px;
$font-size-base-default: 16px;
$font-size-large-default: 18px;
$font-size-larger-default: 22px;
$font-size-largest-default: 32px;
$font-size-xlarge-default: 36px; // added in this variant
$font-size-huge-default: 80px;

$font-size-base: $font-size-base-default; // bootstrap var
$font-size-small: $font-size-small-default; // bootstrap var
$font-size-large: $font-size-large-default; // bootstrap var

$font-weight-light-default: 400;   // no light text in this variant yet
$font-weight-regular-default: 400;
$font-weight-medium-default: 500;
$font-weight-bold-default: 700;
$headings-font-weight: $font-weight-medium-default; // bootstrap var
$btn-font-weight: $font-weight-medium-default;

$line-height-base-default: 1.2;
$line-height-base: $line-height-base-default; // bootstrap var

// these are my variables, based on Figma design
$line-height-body-default: 26px;
$line-height-h3-default: 27px;
$line-height-h2-default: 40px;
$line-height-subheading-default: 34px;
$line-height-h1-default: 45px;

// Links
$link-color: $variant-primary-dark-blue;  // bootstrap var

// Navbar - all of these are bootstrap vars
$navbar-default-bg: $variant-secondary-light-blue;
$navbar-default-link-active-bg: $variant-navbar-text-color;
$navbar-height: 100px;
$navbar-default-link-color: $variant-navbar-text-color;
$navbar-default-link-active-color: $variant-primary-orange;
$navbar-collapse-max-height: 360px;
$navbar-margin-bottom: 0;
$navbar-default-toggle-border-color: $variant-secondary-light-blue;
$navbar-default-toggle-hover-bg: $variant-secondary-light-blue;

// Footer
$footer-height: 100px;

// States, Alerts - bootstrap variables
$state-success-text: $variant-primary-green;
$state-success-bg: $success-bg;
$state-success-border: $variant-primary-green;

$state-danger-text: $variant-primary-red;
$state-danger-bg: $danger-bg;
$state-danger-border: $variant-primary-red;

$alert-border-radius: 0;

//// Buttons
//$btn-font-weight: $font-weight-regular;
//$btn-border-radius-base: 3px;
//
//$btn-default-color: $text-white;
//$btn-default-bg: $orange;
//$btn-default-border: $orange;
//
//$btn-primary-color: $text-white;
//$btn-primary-border: $blue;
//
// Inputs
$input-border: $variant-input-border;
$input-border-focus: $variant-secondary-dark-blue;
$input-color: $text-color-default;
$input-color-placeholder: lighten($text-color-default, 30%);
$input-border-radius: 0;

// Panels
$panel-bg: $variant-secondary-light-blue;
//$panel-inner-border: $variant-secondary-dark-blue;
//$panel-default-border: $variant-secondary-dark-blue;
$panel-heading-padding: 0;
$panel-default-heading-bg: $variant-secondary-light-blue;
$panel-body-padding: 0;
$panel-footer-padding: 0;
$panel-footer-bg: $variant-secondary-light-blue;
$panel-default-border: none;

// Progressbar
$progress-border-radius: 20px;
$progress-bg: $variant-progressbar-bg;
$progress-bar-success-bg: $variant-progressbar-fg;

// Thumbnail
$thumbnail-border: none;
$thumbnail-border-radius: 0;

// Loader (custom component)
$loader-color: $variant-secondary-dark-blue;
$loader-second-color: lighten($loader-color, 10%);
$loader-overlay-color: transparent;

// Table
$table-border-color: none;
$table-bg-accent: $variant-table-stripes;
//$table-bg: $variant-table-stripes;


:root {
    --plyr-color-main: #f15922;
}
