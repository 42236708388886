#focus {
    .part-1 {
        padding: 50px 20px;
    }

    .part-2 {
        padding: 60px 20px 100px 20px;

        h1, h2, h3 {
            text-align: center;
        }

        h2 {
            margin: 30px 0 30px 0;
        }

        .categories-row {
            margin-top: 60px;

            .category-box {
                text-align: center;
                background-color: $variant-secondary-light-blue;
                margin-bottom: 30px;
                padding: 0 0 50px 0;

                //border-bottom-left-radius: 5px;
                border-radius: 0;

                .content-wrapper {
                    padding: 30px 30px 25px 30px;

                    h3 {
                        line-height: 1;
                    }

                    .category-description {
                        padding: 10px 0 0 0;
                    }
                }
            }
        }
    }
}
