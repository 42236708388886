.area-silkroad {
    $area-path: "areas/silkroad/";

    // all these variables are local, they do not override any global variables defined in
    // variables.scss
    $color-1: #023449;
    $color-2: #7591AB;
    $color-3: #F2A850;
    $btn-color: #D25200;

    $color-text: $text-color-default; // NOT the bootstrap var!

    $black: #000000;
    $white: #ffffff;

    $font-size-smallest: 12px;
    $font-size-smaller: 14px;
    $font-size-small: 16px;
    $font-size-base: 18px;
    $font-size-large: 24px;
    $font-size-larger: 30px;
    $font-size-largest: 40px;
    $font-size-huge: 80px;

    $font-weight-light: $font-weight-light-default;
    $font-weight-regular: $font-weight-regular-default;
    $font-weight-medium: $font-weight-medium-default;
    $font-weight-bold: $font-weight-bold-default;

    // ----------------
    //  General styles
    // ----------------
    @include general-text($color-text, $font-size-base);
    @include h1($color-1, $font-size-largest, $line-height: 1);
    @include h2($color-1, $font-size-larger);
    @include h3($color-1, $font-size-large);
    @include h4($color-text, $font-size-base, $line-height: 1);

    .primary-btn {
        @include button-variant($white, $btn-color, $btn-color);
        @include button-size(13px, 51px, $font-size-base, 1, 25px);
        font-weight: $font-weight-medium;
    }

    .secondary-btn {
        @include button-variant($white, $color-2, $color-2);
        @include button-size(13px, 51px, $font-size-base, 1, 25px);
        font-weight: $font-weight-medium;
    }

    .empty-achievements {
        text-align: center;
        color: $white;
    }

    &.ifo-modal {
        .inner-content {
            border: 2px solid $color-1;
        }

        &.achievements {
            @include bg-img($area-path, "progress_achievements.png", 72px) {
                margin: 10px 0;
            };
        }

        &.battles {
            .inner-content { border: none; }
            padding-bottom: 40px !important;

            h3 { @include font-styles($text-color-default, $font-size-base, $font-weight-bold); }

            .secondary-btn {
                @include button-variant($white, $color-2, $color-2);
            }

            &.battles-challenged,
            &.battles-opponent-accepted {
                @include bg-img($area-path, "battles_challenged.png", 200px);
            }
            &.battles-opponent-declined {
                @include bg-img($area-path, "battles_opponent_declined.png", 200px);
            }
            &.battles-won {
                @include bg-img($area-path, "battles_won.png", 200px);
            }
            &.battles-lost {
                @include bg-img($area-path, "battles_lost.png", 200px);
            }
            &.battles-tie {
                @include bg-img($area-path, "battles_tie.png", 200px);
            }
        }

        &.knowledge-test {
            &.knowledge-test-generic {}
            &.knowledge-test-timeout {}
        }
    }

    // ----------------
    //  Pages
    // ----------------

    #onboarding {
        background-color: $color-1;

        .part-1 {
            background-color: $color-1;

            .main-title-row {
                min-height: 430px;

                // @note: height is 546 but the image is too big then, interfering with text
                @include bg-img($area-path, "onboarding_main.png", 340px) {
                    left: 0;
                    bottom: 10%;
                    width: 55%;
                }

                h1 { color: $color-3; }

                .main-title-col {
                    padding-bottom: 50px;
                    .main-subtitle {
                        color: $color-3;
                    }
                }
            }
        }

        .part-2 {
            background-color: $color-2;

            .flavour-text-wrapper {
                background-color: $variant-secondary-light-blue;
                position: relative;

                @include bg-img($area-path, "onboarding_flavour_leg.png", 75px) {
                    display: none;
                    position: absolute;
                    width: 135px;
                    top: 22px;
                    right: -134px;
                    @include respond-to(screen-below-sm) { display: none; }
                }
            }

            .flavour-img-wrapper {
                @include bg-img($area-path, "onboarding_flavour.png", 205px);
            }
        }

        .part-3 {
            background-color: $color-1;

            h1 { color: $color-3; }
            h3 { color: $color-1; }

            .first-instruction {
                @include bg-img($area-path, "onboarding_instructions_first.png", 341px);
            }

            .second-instruction {
                @include bg-img($area-path, "onboarding_instructions_second.png", 341px);
            }

            .third-instruction {
                @include bg-img($area-path, "onboarding_instructions_third.png", 341px);
            }
        }

        .part-4 {
            background-color: $color-2;

            h2 {
                color: $color-1;
            }

            h3 {
                color: $white;
            }

            p {
                color: $white;
            }
        }

        .part-5 {
            background-color: $color-1;
            padding-top: 20px;
            padding-bottom: 20px;

            @include bg-img($area-path, "onboarding_ctoa_illustration.png", 301px) {
                .cta-btn-wrapper {
                    bottom: 37%;
                    left: 42%;

                    // this is specific for Fantasy/Silkroad
                    @include respond-to(screen-below-sm) {
                        left: 30% !important;
                        bottom: 42%;
                    }
                }
            };
        }
    }

    #item-dashboard {
        background-color: $color-1;

        .part-1 {
            background-color: $color-2;

            .item-view-row {

                .item-view-wrapper {
                    .panel-body {
                        @include bg-img($area-path, "item_default.png", 186px);

                        .answer-response-view {
                            &.correct-answer {
                                @include bg-img($area-path, "item_correct_answer.png", 186px);
                            }

                            &.incorrect-answer {
                                @include bg-img($area-path, "item_incorrect_answer.png", 186px);
                            }
                        }

                        .no-items-view {
                            @include bg-img($area-path, "item_clean_table.png", 183px);
                        }
                    }
                }

                .explanation-col {
                    p {
                        color: $white;
                    }
                }
            }
        }

        .part-2 {
            background-color: $color-1;

            h2 {
                color: $color-3;
            }
        }
    }

    #focus {
        background-color: $color-2;

        .part-1 {
            background-color: $color-1;
        }

        .part-2 {
            background-color: $color-2;

            h1 {
                color: $color-1;
            }

            h2 {
                color: $color-1;
            }

            h3 {
                color: $white;
            }

            .categories-row {
                .category-box {

                    .content-wrapper {
                        h3 {
                            color: $color-1;
                        }
                    }

                    &.no-focus {
                        // @todo: the right ratio if it changes
                        @include responsive-bg-img($area-path, "focus_none.png", 0.54054054, $black);
                    }
                }
            }
        }
    }

    #achievements {
        background-color: $color-2;

        .part-1 {
            background-color: $color-1;
            h1 { color: $color-3; }
            h2, h3 { color: $white; }
        }

        .part-2 {
            background-color: $color-2;
            h2, .empty-achievements { color: $white; }
        }
    }

    #leaderboard {
        background-color: $color-2;

        .part-1 {
            background-color: $color-2;

            .rank-section {
                // @todo: the right height
                @include bg-img($area-path, "leaderboard_rank.png", 64px);
            }
        }
    }

    #battles {
        background-color: $color-1;
        h2 { @include font-styles($color-3, $font-size-larger, $font-weight-bold, 1.2); }
        h4 { @include font-styles($color-3, $font-size-base, $font-weight-bold, 1.6); }

        .part-1 {
            background-color: $color-2;

            .main-title-row {
                min-height: 550px;

                @include bg-img($area-path, "battles_main.png", 542px) {
                    left: 0;
                    bottom: 0;
                    max-width: 608px;
                    width: 55%;
                    @include respond-to(screen-sm) { width: 46%; }
                }

                .main-title-col {
                    h1 {
                        color: $color-1;
                    }
                    .main-subtitle {
                        color: $white;
                    }
                }
            }
        }

        .part-2 {
            background-color: $color-1;

            .instruction {
                h3 {
                    font-size: 20px;
                    font-weight: $font-weight-medium-default;
                }
                p { font-size: 16px; }
            }

            .first-instruction {
                @include bg-img($area-path, "battles_instructions_first.png", 280px);
            }

            .second-instruction {
                @include bg-img($area-path, "battles_instructions_second.png", 280px);
            }

            .third-instruction {
                @include bg-img($area-path, "battles_instructions_third.png", 280px);
            }
        }

        .part-3 {
            background-color: $color-2;
            padding-top: 40px;
            padding-bottom: 40px;
            h2 { color: $color-1; }
        }

        .part-4 {
            background-color: $color-1;
        }

        #no-battle {
            @include bg-img($area-path, "battles_no_battle.png", 220px);
            @include placeholder-styles(#fefefe);

            input[type="text"] {
                background-color: #7591AB;
                color: $white;
                border-radius: 25px !important;
            }

            p { color: $white; }

            .autocomplete-wrapper {
                .autocomplete ul,
                .autocomplete ul li a {
                    background-color: #7591AB;
                    color: $white;
                }
            }
        }

        #waiting-for-user {
            @include bg-img($area-path, "battles_challenged.png", 200px);
            p { color: $white; }

            .secondary-btn {
                @include button-variant($white, #7591AB, #7591AB);
            }
        }

        #waiting-for-opponent,
        #battle-waiting-for-finish {
            @include bg-img($area-path, "battles_waiting.png", 200px);
            p { color: $white; }
        }

        #battle-in-progress {
            color: $white;

            #header-row {
                p { color: $white; }
            }

            #battle-progress {
                background-color: $white;

                .question-progress-wrapper {
                    //p { color : #231f20; }

                    // @note: this pictures should ideally be square
                    .question-correct {
                        @include bg-img($area-path, "battles_question_correct.png", 32px) { max-width: 32px; };
                    }
                    .question-incorrect {
                        @include bg-img($area-path, "battles_question_incorrect.png", 32px) { max-width: 32px; };
                    }
                    .question-unknown {
                        @include bg-img($area-path, "battles_question_unknown.png", 32px) { max-width: 32px; };
                    }
                    // @note: it's best for these pictures to be exactly 50px high
                    .question-both-correct { @include bg-img($area-path, "battles_question_both_correct.png", 50px) }
                    .question-both-incorrect { @include bg-img($area-path, "battles_question_both_incorrect.png", 50px); }
                    .question-won { @include bg-img($area-path, "battles_question_won.png", 50px); }
                    .question-lost { @include bg-img($area-path, "battles_question_lost.png", 50px); }
                    .question-wait { @include bg-img($area-path, "battles_question_wait.png", 50px); }
                }
            }
        }
    }

    .knowledge-test-box {
        @include responsive-bg-img($area-path, "focus_knowledge_test.png", 0.54054054, $black);
    }

    #knowledge-test {
        background-color: $color-1;
        .part-1 { background-color: $color-1; }
    }
}

// this must be outside of area class parent - because it is also used in Profile for each area
#profile, #area-chooser {
    .area-specific-part.part-silkroad {
        background-color: #7591AB;

        .area-description-row {
            min-height: 200px; // @note: this is set to the height of the image + padding from top
            @include bg-img("areas/silkroad/", "profile_area_specific.png", 200px);

            .area-description-col {
                h2 {
                    color: #023449;
                }

                p, .area-finished-text {
                    color: $white;
                }
            }
        }

        .primary-btn {
            @include button-variant($white, #D25200, #D25200);
            @include button-size(13px, 51px, 16px, 1, 25px);
            font-weight: 500;
        }
    }
}

.area-silkroad .user-progress,
.area-specific-part.part-silkroad  .user-progress {
    .metric-row {
        .metric {
            padding-top: 7px;
            .metric-title {
                padding-top: 5px;
            }
        }

    }
    .progress-level {
        .bg-img {
            // image url is set from code as it changes w.r.t. level
            // @todo: set the right height
            height: 64px;
        }
    }

    .progress-items {
        @include bg-img("areas/silkroad/", "progress_items.png", 64px);
    }

    .progress-rank {
        @include bg-img("areas/silkroad/", "progress_rank.png", 64px);
    }

    .progress-achievements {
        @include bg-img("areas/silkroad/", "progress_achievements.png", 72px);
    }

    .progress-battles {
        @include bg-img("areas/silkroad/", "progress_battles.png", 64px);
    }
}
