.area-cybersecurity {
    $area-path: "areas/cybersecurity/";

    // all these variables are local, they do not override any global variables defined in
    // variables.scss
    $area-primary-dark-blue: #00497C;           // backgrounds
    $area-primary-light-blue: #6ECFF6;          // headings
    $area-primary-green: #009547;               // primary buttons

    $area-secondary-future-darkblue: #20206B;   // backgrounds
    $area-secondary-future-cyan: #5CF6C2;       // main headings
    $area-secondary-future-lilac: #7E4FB1;      // secondary buttons
    $area-secondary-dark-blue: #023449;         // leaderboard h2

    $area-text-2: #41405E;                      // some few texts

    $btn-color: $area-primary-green;
    $color-text: #303030; // NOT the bootstrap var!
    $black: #000000;
    $white: #ffffff;
    $gray-footer: #e2e2e2;

    $font-size-smallest: 14px;
    $font-size-smaller: 14px;
    $font-size-small: 14px;
    $font-size-base: 16px;
    $font-size-large: 18px;
    $font-size-larger: 22px;
    $font-size-largest: 32px;
    $font-size-xlarge: 36px; // added in this variant
    $font-size-huge: 80px;

    $font-weight-light: 400;
    $font-weight-regular: 400;
    $font-weight-medium: 500;
    $font-weight-bold: 700;

    $line-height-body: 26px;
    $line-height-h3: 27px;
    $line-height-h2: 40px;
    $line-height-subheading: 34px;
    $line-height-h1: 45px;


    // ----------------
    //  General styles
    // ----------------
    @include general-text($color-text, $font-size-base, $font-weight-regular, $line-height-body);
    @include h1($area-secondary-future-cyan, $font-size-xlarge, $font-weight-bold, $line-height-h1);
    @include h2($area-secondary-future-cyan, $font-size-largest, $font-weight-bold, $line-height-h2);
    @include h3($area-secondary-future-cyan, $font-size-large, $font-weight-bold, $line-height-h3);
    @include h4($area-secondary-future-cyan, $font-size-base, $font-weight-regular, 1);
    .subheading {
        @include font-styles($color-text, $font-size-larger, $font-weight-medium, $line-height-subheading);
    }

    .primary-btn {
        @include button-variant($white, $area-primary-green, $area-primary-green);
        @include button-size(15px, 20px, $font-size-base, 1.1, 5px);
        font-weight: $font-weight-medium;
    }

    .secondary-btn {
        @include button-variant($white, $area-secondary-future-lilac, $area-secondary-future-lilac);
        @include button-size(15px, 20px, $font-size-base, 1.1, 5px);
        font-weight: $font-weight-medium;
    }

    .empty-achievements {
        text-align: center;
        color: $white;
    }

    &.ifo-modal {
        .inner-content {
            border: 2px solid $variant-secondary-dark-blue;
        }

        &.achievements {
            @include bg-img($area-path, "progress_achievements.png", 64px) {
                margin: 35px 0 25px 0;
            };
        }

        &.battles {
            .inner-content { border: none; }
            padding-bottom: 40px !important;

            h3 { @include font-styles($text-color-default, $font-size-base, $font-weight-bold); }

            .secondary-btn {
                //@include button-variant($white, #37474f, #37474f);
            }

            &.battles-challenged,
            &.battles-opponent-accepted {
                @include bg-img($area-path, "battles_challenged.png", 200px) {
                    margin-top: 30px;   // because image has no margins
                    margin-bottom: 30px;
                }
            }
            &.battles-opponent-declined {
                @include bg-img($area-path, "battles_opponent_declined.png", 200px) {
                    margin-top: 30px;   // because image has no margins
                    margin-bottom: 30px;
                }
            }
            &.battles-won {
                @include bg-img($area-path, "battles_won.png", 200px) {
                    margin-top: 30px;   // because image has no margins
                    margin-bottom: 30px;
                }
            }
            &.battles-lost {
                @include bg-img($area-path, "battles_lost.png", 200px) {
                    margin-top: 30px;   // because image has no margins
                    margin-bottom: 30px;
                }
            }
            &.battles-tie {
                @include bg-img($area-path, "battles_tie.png", 200px) {
                    margin-top: 30px;   // because image has no margins
                    margin-bottom: 30px;
                }
            }
        }

        &.knowledge-test {
            &.knowledge-test-generic {}
            &.knowledge-test-timeout {}
        }
    }

    // ----------------
    //  Pages
    // ----------------

    #onboarding {
        background-color: $area-primary-dark-blue;

        .part-1 {
            background-color: $area-secondary-future-darkblue;

            .main-title-row {
                min-height: 550px;

                @include bg-img($area-path, "onboarding_main.png", 450px) {
                    left: 0;
                    bottom: 50px;
                    width: 50%;
                }

                .main-title-col {
                    padding-top: 140px;
                    .main-subtitle {
                        margin-top: 25px;
                        // subheading
                        @include font-styles($white, $font-size-larger, $font-weight-medium, $line-height-subheading);
                    }
                }
            }
        }

        .part-2 {
            background-color: $area-secondary-future-cyan;

            .flavour-text-wrapper {
                background-color: $area-secondary-future-cyan;
                position: relative;

                .flavour-text {
                    // subheading
                    @include font-styles($area-text-2, $font-size-larger, $font-weight-medium, $line-height-subheading);
                }
                .flavour-text-tagline {
                    // subheading
                    @include font-styles($area-text-2, $font-size-larger, $font-weight-bold, $line-height-subheading);
                }

                // @note: leg not present in this design
                //@include bg-img($area-path, "onboarding_flavour_leg.png", 75px) {
                //    position: absolute;
                //    width: 135px;
                //    top: 40px;
                //    right: -134px;
                //    @include respond-to(screen-below-sm) { display: none; }
                //}
            }

            .flavour-img-wrapper {
                @include bg-img($area-path, "onboarding_flavour.png", 200px);
            }
        }

        .part-3 {
            background-color: $area-primary-dark-blue;

            h1 {
                color: $area-secondary-future-cyan;
                text-align: center;
            }

            .instruction {
                h3 {
                    color: $area-primary-dark-blue;
                }
            }

            .first-instruction {
                @include bg-img($area-path, "onboarding_instructions_first.png", 340px);
            }

            .second-instruction {
                @include bg-img($area-path, "onboarding_instructions_second.png", 340px);
            }

            .third-instruction {
                @include bg-img($area-path, "onboarding_instructions_third.png", 340px);
            }
        }

        .part-4 {
            background-color: $area-secondary-future-darkblue;

            h2 {
                color: $area-secondary-future-cyan;
            }

            h3 {
                color: $area-primary-light-blue;
            }

            p {
                color: $white;
            }
        }

        .part-5 {
            background-color: $area-primary-dark-blue;
            padding: 100px 0;

            .cta-btn {
                @include button-variant($white, $area-secondary-future-darkblue, $area-secondary-future-darkblue);
            }

            @include bg-img($area-path, "onboarding_ctoa_illustration.png", 310px) {
                .cta-btn-wrapper {
                    bottom: 43%;
                    left: 41%;
                }
            };
        }
    }

    #item-dashboard {
        background-color: $area-secondary-future-darkblue;

        .part-1 {
            background-color: $area-primary-dark-blue;

            .item-view-row {

                .item-view-wrapper {
                    .panel-body {
                        @include bg-img($area-path, "item_default.png", 190px) {
                            margin-top: 20px;   // because image has no margins
                        };

                        .answer-response-view {
                            &.correct-answer {
                                @include bg-img($area-path, "item_correct_answer.png", 190px);
                            }

                            &.incorrect-answer {
                                @include bg-img($area-path, "item_incorrect_answer.png", 192px);
                            }
                        }

                        .no-items-view {
                            @include bg-img($area-path, "item_clean_table.png", 190px);
                        }
                    }
                }

                .explanation-col {
                    h3 {
                        color: $area-primary-light-blue;
                    }

                    p {
                        color: $white;
                    }
                }
            }
        }

        .part-2 {
            background-color: $area-secondary-future-darkblue;

            h2 {
                color: $area-secondary-future-cyan;
            }

            .empty-achievements {
                color: $white;
            }
        }
    }

    #focus {
        background-color: $area-secondary-future-darkblue;

        .part-1 {
            background-color: $area-primary-dark-blue;
        }

        .part-2 {
            background-color: $area-secondary-future-darkblue;

            h1 {
                color: $area-secondary-future-cyan;
            }

            h2 {
                // subheading
                @include font-styles($area-primary-light-blue, $font-size-larger, $font-weight-medium, $line-height-subheading);
            }

            h3 {
                // "regular text"
                @include font-styles($white, $font-size-base, $font-weight-regular, $line-height-body);
            }

            .categories-row {
                .category-box {

                    .content-wrapper {
                        @include h3($area-primary-dark-blue, $font-size-large, $font-weight-bold, $line-height-h3);
                    }

                    &.no-focus {
                        // @note: insert the correct height to width ratio if it changes
                        @include responsive-bg-img($area-path, "focus_none.png", 0.54054054, $black);
                    }
                }
            }
        }
    }

    #achievements {
        background-color: $area-primary-dark-blue;

        .part-1 {
            background-color: $area-secondary-future-darkblue;

            h1 {
                color: $area-secondary-future-cyan;
            }

            h2 {
                color: $area-primary-light-blue;
            }

            h3 {
                display: none;
            }
        }

        .part-2 {
            background-color: $area-primary-dark-blue;

            h2 {
                color: $area-primary-light-blue;
            }

            .empty-achievements {
                color: $white;
            }
        }
    }

    #leaderboard {
        background-color: $area-primary-dark-blue;

        .part-1 {
            background-color: $area-primary-dark-blue;

            h2 {
                color: $area-secondary-dark-blue;
            }

            .rank-section {
                @include bg-img($area-path, "leaderboard_rank.png", 64px);
            }
        }
    }

    #battles {
        background-color: $area-secondary-future-darkblue;
        //h2 { @include font-styles(#ffcc80, $font-size-larger, $font-weight-bold, 1.2); }
        //h4 { @include font-styles(#ffcc80, $font-size-base, $font-weight-bold, 1.6); }

        .part-1 {
            background-color: $area-secondary-future-darkblue;

            .main-title-row {
                min-height: 500px;

                @include bg-img($area-path, "battles_main.png", 550px) {
                    left: 0;
                    max-width: 620px;
                    width: 57%;
                    @include respond-to(screen-sm) { width: 50%; }
                }

                .main-title-col {
                    h1 {}
                    .main-subtitle {
                        @include font-styles($white, $font-size-larger, $font-weight-medium, $line-height-subheading);
                        margin-top: 20px;
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .part-2 {
            background-color: $area-primary-dark-blue;

            .instruction {
                h3 {}
                p {}
            }

            .first-instruction {
                @include bg-img($area-path, "battles_instructions_first.png", 280px);
            }

            .second-instruction {
                @include bg-img($area-path, "battles_instructions_second.png", 280px);
            }

            .third-instruction {
                @include bg-img($area-path, "battles_instructions_third.png", 280px);
            }
        }

        .part-3 {
            background-color: $area-secondary-future-darkblue;
        }

        .part-4 {
            background-color: $area-secondary-future-darkblue;
        }

        #no-battle {
            @include bg-img($area-path, "battles_no_battle.png", 200px) {
                margin-bottom: 30px;   // because image has no margins
            }
            @include placeholder-styles(#ffffff);

            input[type="text"] {
                background-color: $variant-secondary-dark-blue;
                color: $white;
            }

            p { color: $white; }

            .autocomplete-wrapper {
                .autocomplete ul,
                .autocomplete ul li a {
                    background-color: $variant-secondary-dark-blue;
                    color: $white;
                }
            }
        }

        #waiting-for-user {
            @include bg-img($area-path, "battles_challenged.png", 200px) {
                margin-bottom: 30px;   // because image has no margins
            }
            p { color: $white; }

            .secondary-btn {
                //@include button-variant($white, #37474F, #37474F);
            }
        }

        #waiting-for-opponent,
        #battle-waiting-for-finish {
            @include bg-img($area-path, "battles_waiting.png", 200px) {
                margin-bottom: 30px;   // because image has no margins
            }
            p { color: $white; }
        }

        #battle-in-progress {
            color: $white;

            #header-row {
                p { color: $white; }
            }

            #battle-progress {
                //background-color: $white;

                .question-progress-wrapper {
                    p {
                        //color : #231f20;
                    }

                    .question-correct {
                        @include bg-img($area-path, "battles_question_correct.png", 32px) { max-width: 32px; };
                    }
                    .question-incorrect {
                        @include bg-img($area-path, "battles_question_incorrect.png", 32px) { max-width: 32px; };
                    }
                    .question-unknown {
                        @include bg-img($area-path, "battles_question_unknown.png", 32px) { max-width: 32px; };
                    }
                    .question-both-correct { @include bg-img($area-path, "battles_question_both_correct.png", 50px) }
                    .question-both-incorrect { @include bg-img($area-path, "battles_question_both_incorrect.png", 50px); }
                    .question-won { @include bg-img($area-path, "battles_question_won.png", 50px); }
                    .question-lost { @include bg-img($area-path, "battles_question_lost.png", 50px); }
                    .question-wait { @include bg-img($area-path, "battles_question_wait.png", 50px); }
                }
            }
        }
    }

    .knowledge-test-box {
        @include responsive-bg-img($area-path, "focus_knowledge_test.png", 0.54054054, $black);
    }

    #knowledge-test {
        background-color: #243a56;
        .part-1 { background-color: #243a56; }
    }
}

// this must be outside of area class parent - because it is also used in Profile for each area
#profile, #area-chooser {
    .area-specific-part.part-cybersecurity {
        background-color: #20206B;

        .area-description-row {
            min-height: 200px; // @note: this is usually set to the height of the image + padding from top
            @include bg-img("areas/cybersecurity/", "profile_area_specific.png", 200px);

            .area-description-col {
                h2 {
                    color: #5CF6C2;
                }

                p, .area-finished-text {
                    color: $white;
                }
            }
        }

        .primary-btn {
            @include button-variant($white, #009547, #009547);
            @include button-size(15px, 20px, 16px, 1.1, 5px);
            font-weight: 500;
        }
    }
}

.area-cybersecurity .user-progress,
.area-specific-part.part-cybersecurity .user-progress {
    .metric-row {
        .metric {
            padding-top: 7px;
            .metric-title {
                padding-top: 5px;
            }
        }

    }
    .progress-level {
        .bg-img {
            // image url is set from code as it changes w.r.t. level
            height: 64px;
        }
    }

    .progress-items {
        @include bg-img("areas/cybersecurity/", "progress_items.png", 64px);
    }

    .progress-rank {
        @include bg-img("areas/cybersecurity/", "progress_rank.png", 64px);
    }

    .progress-achievements {
        @include bg-img("areas/cybersecurity/", "progress_achievements.png", 64px);
    }

    .progress-battles {
        @include bg-img("areas/cybersecurity/", "progress_battles.png", 64px);
    }
}
