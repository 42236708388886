#not-found {
    background-color: $variant-primary-dark-blue;

    .part-1 {
        padding: 100px 20px 50px 20px;
        background-color: $variant-primary-dark-blue;
        text-align: center;

        h1 {
            color: $white;
            line-height: 1.1;
        }

        p {
            color: $white;
            padding: 50px;
        }
    }
}
