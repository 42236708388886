#onboarding {

    .part-1 {
        padding: 0px 20px;

        .main-title-row {
            position: relative;
            min-height: 500px;

            .bg-img {
                position: absolute;
                @include respond-to(screen-below-sm) { display: none; }
            }

            .main-title-col {
                padding: 80px 0 20px 0;

                h1 { line-height: 1.1; }

                .main-subtitle {
                    padding-top: 5px;
                    line-height: 2;
                }
            }
        }
    }

    .part-2 {
        padding: 55px 0 75px 0;

        .flavour-text-wrapper {
            border-radius: 0px;
            padding: 15px 45px;
            margin: 20px 40px 0 80px;
            @include respond-to(screen-below-md){ margin: 20px 0 0 0; }

            .flavour-text-tagline {
                font-weight: $font-weight-medium-default;
            }
        }

        .flavour-img-wrapper {
            margin-top: 20px;
        }
    }

    .part-3 {
        padding: 100px 0;

        h1 {
            padding: 0 0 50px 0;
        }

        .instruction {
            .image-col {
                padding-top: 20px;
            }

            // @note this inherits from common
            .instruction-wrapper {
                margin: 70px 50px 0 50px;
                @include respond-to(screen-below-lg) { margin: 50px 0 20px 0; }
            }
        }

        .first-instruction {
            margin-bottom: 50px;
        }

        .second-instruction {
            margin-bottom: 50px;

            // so that these columns can be swapped from CSS
            .image-col {
                @include make-sm-column-push(6);
            }
            .instruction-col {
                @include make-sm-column-pull(6);
            }
        }

        .third-instruction {
        }
    }

    .part-4 {
        padding: 100px 0;
        text-align: center;

        h2 {
            //line-height: 1.2;
            padding-bottom: 30px;
        }

        h3 {
            padding-top: 50px;
            padding-bottom: 30px;
        }
    }

    .part-5 {
        padding: 0;

        .bg-img {
            position: relative;

            .cta-btn-wrapper {
                position: absolute;

                @include respond-to(screen-below-sm) {
                    left: 0 !important;
                }
            }
        }
    }
}
