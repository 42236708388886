#achievements {
    .part-1 {
        padding: 60px 20px 70px 20px;

        h1 {
            margin-bottom: 100px;
        }

        h1, h3 {
            text-align: center;
        }

        .achievements-title {
            margin-top: 40px;
            margin-bottom: 30px;
        }
    }

    .part-2 {
        padding: 100px 20px 70px 20px;

        .achievements-title {
            margin-bottom: 30px;
        }

        .continue-btn-row {
            margin-top: 40px;
            text-align: center;
        }
    }
}
