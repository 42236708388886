// ----------------
//  Navbar
// ---------------
.navbar {
    border-width: 0px;

    .navbar-header {
        .navbar-brand {
            padding-top: 20px;
            padding-bottom: 15px; // less is safer, to prevent navbar expanding vertically
            padding-right: 19px;

            .logo-navbar {
                height: 50px;
            }
        }
        @include respond-to(screen-sm) { display: none }
    }

    .navbar-nav {
        @include respond-to(navbar-collapsed) {
            margin-top: 5px;
            margin-bottom: 0;
        }
    }

    .navbar-nav > li {
        font-size: $font-size-base-default;
        font-weight: $font-weight-medium-default;
        border-bottom: 1px solid $variant-secondary-light-blue;

        &.active {
            //color: $variant-secondary-dark-blue;
        }

        > a {
            &:focus, &:hover {
                color: $variant-navbar-text-color;
            }
            padding-left: 17px;
            padding-right: 17px;
            @include respond-to(screen-sm) {
                padding-left: 8px;
                padding-right: 8px;
            }

            @include respond-to(screen-md) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        &.user-info {
            padding-top: 32px;
            padding-bottom: 32px;

            @include respond-to(navbar-collapsed) {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .user-info-content-wrapper {
                padding-left: 10px;
                padding-right: 10px;
                border-right: 1px solid #20252b;
                max-width: 200px; // empirically found to prevent two lines in navbar

                @include respond-to(screen-md) {
                    max-width: 117px; // empirically found to prevent two lines in navbar
                }
                @include respond-to(screen-sm) {
                    max-width: 97px; // empirically found to prevent two lines in navbar
                }
                @include respond-to(navbar-collapsed) {
                    border: none;
                    max-width: none;
                }
            }

            a, a:focus, a:hover {
                text-decoration: none !important;
                // in Akademia variant, we have area chooser instead of Profile
                pointer-events: none !important;
                cursor: auto;
            }

            a {
                white-space: nowrap;
                overflow: hidden;
                .nav-link {
                    text-overflow: ellipsis;
                }

                color: $variant-navbar-text-color;
                padding: 0 10px;
                font-weight: $font-weight-medium-default;
                font-size: $font-size-small-default;
                line-height: 1.3;
                position: relative;
                display: block;
                text-decoration: none;
            }

            .user-points {
                color: $variant-primary-green;
                font-weight: $font-weight-medium-default;
                text-transform: uppercase;
                cursor: pointer;
            }

            &.active{
                background-color: $navbar-default-link-active-bg;
            }
        }

        &.logout {
            a {
                padding-right: 0;
                font-size: $font-size-small-default;
                font-weight: $font-weight-medium-default;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
                cursor: pointer;
            }

            i {
                color: $variant-primary-red;
                margin-right: 3px;
            }
        }


        .navbar-btn {
            margin-top: 17px;
            margin-bottom: 17px;
            padding-left: 15px;
            padding-right: 15px;

            @include respond-to(navbar-collapsed) {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }

    .navbar-toggle, .navbar-toggle:hover, .navbar-toggle:focus {
        border-radius: 0;
    }

    // the main login page before user is logged in
    &.guest-navbar {
        padding-top: 50px;
        background: linear-gradient(264.77deg, $variant-secondary-future-darkblue 1.39%, #7E4FB1 98.78%);

        .container {
            background-color: #E4EAF0;
            padding-left: 30px;
            padding-right: 30px;
            box-shadow: 1px 4px 50px 0px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
        }

        @include respond-to(screen-below-sm){
            padding-top: 0px;
            text-align: center;
            .container {
                border-radius: 0px;
            }
        }

        .navbar-header {
            @include respond-to(screen-sm) { display: block }
        }

        .navbar-nav > li .navbar-btn {
            margin-top: 25px;
            margin-bottom: 20px;
            //padding-right: 0px;
        }

        .navbar-nav > li > a,
        .navbar-nav > .active > a,
        .navbar-nav > .active > a:hover,
        .navbar-nav > .active > a:focus {
            color: $white;
            font-weight: $font-weight-medium-default;
            background-color: $variant-secondary-light-blue;
            cursor: pointer;
        }

        .navbar-toggle, .navbar-toggle:hover, .navbar-toggle:focus {
            //background-color: $variant-secondary-light-blue;
            border-radius: 0;
        }
    }
}
