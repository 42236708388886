footer {
    background: $variant-footer;
    @include font-styles(#FFFFFF, $font-size-small-default, $font-weight-regular-default);
    border: none;
    padding: 15px 0 15px 0;

    @include flexbox();
    align-items: center;
    @include respond-to(screen-below-sm) {
        display: block;
    }

    .img {
        max-height: 50px;
    }

    a {
        color: $variant-primary-orange;
        text-decoration: none;
    }

    .row {
        @include flexbox();
        align-items: center;
        @include respond-to(screen-below-sm) {
            display: block;
        }
    }

    .left-column {
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: left;
        @include respond-to(screen-below-sm) {
            text-align: center
        }
    }

    .middle-column {
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
    }

    .right-column {
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: right;
        @include respond-to(screen-sm) {
            text-align: center
        }
        @include respond-to(screen-below-sm) {
            text-align: center
        }
    }
}
