// ----------------
//  Utilities
// ----------------
[v-cloak] {
    display: none;
}

.vertical-align {
    @include flexbox();
    align-items: center;
}

// ----------------
//  Buttons
//  these are different from those defined by areas! Those are e.g. .primary-btn, .secondary-btn
// ----------------
.brand-primary-btn {
    @include button-variant($white, $variant-primary-green, $variant-primary-green);
    @include button-size(15px, 20px, $font-size-base-default, 1.1, 5px);
    font-weight: $font-weight-medium-default;
}

.brand-secondary-btn {
    @include button-variant($white, $variant-primary-green, $variant-primary-green);
    @include button-size(15px, 20px, $font-size-base-default, 1.1, 5px);
    font-weight: $font-weight-medium-default;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: 0;
}

.btn-success {
    //@include button-variant($white, $color-1, $color-1);
    @include button-size(13px, 51px, $font-size-base-default, 1, 25px);
    font-weight: $font-weight-medium-default;
}

// ----------------
// Input, Form Control
// ----------------
// IE hack - hiding the "X" and password button from IE
input::-ms-clear, input::-ms-reveal {
    display: none;
}

.form-control {
    font-size: $font-size-small-default;
}

.form-group {
    margin-bottom: 0;

}

.validation-message-wrapper {
    display: block;
    min-height: 28px;
    margin-top: 8px;
}


label {
    @include font-styles($gray, $font-size-smaller-default, $font-weight-medium-default);
}

// ----------------
//  Selection (e.g. text selected by mouse)
// ----------------
::selection {
    background: $brand-primary;
    color: $white;
    text-shadow: 0px 0px 0px;
}

::-moz-selection {
    background: $brand-primary;
    color: $white;
    text-shadow: 0px 0px 0px;
}

// ----------------
//  General layout
// ----------------
html, body, #app {
    height: 100%;
}

// @todo: wtf is this?
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#wrap {
    min-height: 100%;
}

#main {
    overflow: auto;
    padding-bottom: $footer-height;
}

footer {
    position: relative;
    margin-top: -$footer-height;
    min-height: $footer-height;
    clear: both;
}

// fixing problems with not enough content before footer
.page {
    min-height: calc(100vh - #{$navbar-height} - 1px - #{$footer-height});
}


// ----------------
//  Headings
// ----------------
@include h1($variant-secondary-dark-blue);
@include h2($variant-secondary-dark-blue);
@include h3($variant-secondary-dark-blue);
@include h4($variant-secondary-dark-blue);

h1, h2, h3, h4, p {
    padding: 0;
    margin: 0;
}

// ----------------
//  Sweetalert 2
// ----------------
.ifo-modal {
    font-family: "Inter", sans-serif;
    border-radius: 0;
    padding: 15px;

    background-color: $variant-secondary-light-blue !important;

    .inner-content {
        padding: 30px 15px 15px 15px;
    }

    // probably unused anyway, we use custom bg-img for achievements and levels
    .ifo-modal-img {
        max-height: 120px;
    }

    .swal2-title {
        display: none;
    }

    h2  {
        color: $variant-secondary-dark-blue;
        line-height: 1;
    }

    p {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &.achievements {
        .bg-img {
            margin: 25px 0;
        }
    }

    &.level {
        .img {
            padding: 25px 0 25px 0;
        }
    }

    .level-name {
        font-weight: $font-weight-medium-default;
    }

    .list-item {
        @include box-shadow(1px 4px 50px 0px rgba(0, 0, 0, 0.15));
    }

    &.battles {
        .swal2-buttonswrapper { margin-top: 0; }
        .primary-btn { margin-top: 10px; }
        .secondary-btn { margin: 10px 0 0 15px; }

        .opponent-name { color: $variant-primary-red; }

        &.battles-won {
            background-color: $variant-primary-green-light !important;
            h2 { color: $white; }
            h3 { color: $white; }
            p { color: $white; }
            .primary-btn {
                @include button-variant($variant-primary-green-light, $white, $white);
            }
        }
        &.battles-lost {
            background-color: $variant-primary-red !important;
            h2 { color: $white; }
            h3 { color: $white; }
            p { color: $white; }
            .primary-btn {
                @include button-variant($variant-primary-red, $white, $white);
            }
        }
        &.battles-tie {
            background-color: $variant-primary-orange !important;
            h2 { color: $white; }
            h3 { color: $white; }
            p { color: $white; }
            .primary-btn {
                @include button-variant($variant-primary-orange, $white, $white);
            }
        }
    }

    &.knowledge-test {
        h3 {
            color: $variant-secondary-dark-blue;
        }
        h4 {
            color: $variant-secondary-dark-blue;
            padding-bottom: 10px;
        }

        .primary-btn {
            display: block;
            margin-left: auto;
            margin-right: auto;
            min-width: 260px;
        }
        .secondary-btn {
            display: block;
            margin-top: 10px;
            min-width: 260px;
        }
    }
}

// ----------------
//  Images
// ----------------
.img {
    margin-left: auto;
    margin-right: auto;
}

// see also bg-img mixin
.bg-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
}

// see also responsive-bg-img mixin
.responsive-bg-img {
}

// ----------------
//  Panels
// ----------------
.panel.panel-default {
    border-radius: 0;
    border: none;

    padding: 15px;
    .panel-body {
        padding: 15px;
    }

    .panel-heading {
        text-align: center;
        @include font-styles($text-color-default, $font-size-base-default, $font-weight-medium-default);
    }
}

// ----------------
//  List items - e.g. achievements
// ----------------
.list-item {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 25px 25px 25px 15px;
    @include box-shadow(1px 4px 50px 0px rgba(0, 0, 0, 0.15));
    border-radius: 0 !important;
    border: none;
    background-color: $variant-secondary-light-blue;

    .list-item-row {
        @include flexbox();
        @include align-items(center);
    }

    .img-wrapper {
        min-height: 50px;
    }

    @include h4($variant-secondary-dark-blue, $font-size-large-default, $font-weight-bold-default, $line-height: 1.1) {
        text-align: left;
        margin-bottom: 10px;
    }

    p {
        text-align: left;
        @include font-styles($text-color-default, $font-size-base-default, $font-weight-regular-default, $line-height-body-default);
    }

    .title-wrapper {
        // overriding the normal col- classes in this variant
        width: 100%;
    }

    .tag {
        @include font-styles($variant-secondary-dark-blue, $font-size-smallest-default, $font-weight-medium-default, 1.2);
        text-align: right;
        position: absolute;
        right: 0;
        top: -20px;
        width: 100%;

        @include respond-to(screen-below-sm) {
            text-align: left;
            position: static;
        }

    }
}

.list-group-item:last-child,
.list-group-item:first-child {
    margin-bottom: 15px;
}

// ----------------
//  Alerts
// ----------------
.alert {
    white-space: pre-wrap;
    word-wrap: break-word;

    &.top-right {
        top: 100px !important;
        right: 30px !important;
    }
}

// ----------------
//  Progress bar
// ----------------
.progress {
    @include box-shadow(none);
    margin-bottom: 0;
    height: 16px;
    padding: 3px;

    .progress-bar {
        border-radius: 20px 0 0 20px;

        &.progress-bar-striped {
            background-image: none;
        }
    }

    &.progress-full {
        .progress-bar {
            border-radius: 20px;
        }
    }
}


// ----------------
//  Custom checkbox - A, B, C in circles
// ----------------
.custom-checkbox {
    margin: 5px 0;

    .checkbox-col {
        padding-right: 0;
    }

    .tag-col {
        padding-top: 5px;
        padding-left: 0
    };

    .regular-checkbox {
        display: none;
    }

    label {
        background-color: $variant-secondary-light-blue;
        border: 1px solid $variant-primary-dark-blue;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        margin-bottom: 0;
        display: inline-block;
        cursor: pointer;

        @include font-styles($variant-secondary-dark-blue, $font-size-smaller-default, $font-weight-medium-default, 1);

        padding: 11px 0px;
        //padding: 12px 14px;
        position: relative;
        text-align: center;
    }

    &.checked label {
        background-color: $variant-secondary-dark-blue;
        color: $white;
        @include font-styles($white, $font-size-smaller-default, $font-weight-medium-default, 1);
    }

    .tag {
        @include font-styles($text-color-default, $font-size-base-default, $font-weight-light-default, 1);
        display: inline;
    }

    &.checked .tag {
        font-weight: $font-weight-medium-default;
    }
}

// ----------------
//  Labels (not input labels, but bootstrap labels)
// ----------------
.label.label-important {
    @include label-variant($variant-primary-red);
    vertical-align: middle;
}

h3 .label.label-important {
    font-size: $font-size-smaller-default;
}


// ----------------
//  Instructions box with shadow - e.g. Onboarding, Battles
// ----------------
.instruction-wrapper {
    background: $variant-secondary-light-blue;
    @include box-shadow(1px 4px 50px 0px rgba(0, 0, 0, 0.15));
    border-radius: 0px;

    //padding: 30px 0px 30px 30px;
    padding: 30px;

    h3 {
        @include font-styles($text-color-default, $font-size-large-default, $font-weight-bold-default, $line-height-h3-default);
        padding-bottom: 25px;
    }

    .number {
        //@include font-styles($brand-6, $font-size-huge-default, $font-weight-bold-default, 1);
        // @note: not used in this variant, at least not in the first area
        display: none;
    }
    .instruction-text {
        width: 100%;
    }
}

// ----------------
//  TextAnswer, ChoiceAnswer - in ItemDashboard and Battles
// ----------------
.item-answer {
    h4 {
        color: $variant-secondary-dark-blue;
        padding-bottom: 10px;
    }

    .text-answer-wrapper {
        position: relative;

        #answer {
            padding-right: 55px;
        }

        .text-answer-length-counter {
            @include font-styles($variant-secondary-dark-blue, $font-size-smaller-default, $font-weight-medium-default);
            display: inline-block;
            position: absolute;
            right: 6px;
            /*top: 0;*/
            z-index: 1;
        }
    }
}

// ----------------
//  Animations
// ----------------
.component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to {
    opacity: 0;
}


// ----------------
//  Tooltips (e.g. knowledge test item names on pagination hover)
//  https://codepen.io/otwm/pen/yLLwvJz
// ----------------
.tooltip-container {
    position: relative;
    &:hover {
        .tooltip-content {
            visibility: visible;
            opacity: 1;
            transition: .25s all ease;
            transition-delay: 0s;
            top: -85px;
        }
    }
}
.tooltip-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: $black;
    left: -150px;
    top: -75px;
    padding: 7px 10px;
    margin: 16px;
    font-size: $font-size-base;
    font-weight: $font-weight-bold-default;
    text-align: center;
    width: 300px;
    transition: .25s all ease;
    //transition-delay: .25s;
    z-index: 2;
    color: $white;
    &:after {}
}

.disable-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}
