.loader-wrapper {
    pointer-events: none; // clicks pass through
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;  // Above everything...
    overflow: hidden;
    //background-color: rgba(0, 0, 0, 0.18);
    background-color: $loader-overlay-color;
}

.loader {
    font-size: 10px;
    position: absolute;
    z-index: 10000; // Above everything...
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    text-indent: -9999em;
    border-top: 5px solid $loader-second-color;
    border-right: 5px solid $loader-second-color;
    border-bottom: 5px solid $loader-second-color;
    border-left: 5px solid $loader-color;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loaderSpinner 1.1s infinite linear;
    animation: loaderSpinner 1.1s infinite linear;

    &,
    &:after {
        border-radius: 50%;
        width: 40px;
        height: 40px
    }
}

@-webkit-keyframes loaderSpinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
