@mixin font-styles(
    $color: $text-color-default,
    $font-size: $font-size-base-default,
    $font-weight: $font-weight-regular-default,
    $line-height: $line-height-base-default
) {
    color: $color;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}

@mixin general-text(
    $color: $text-color-default,
    $font-size: $font-size-base-default,
    $font-weight: $font-weight-light-default,
    $line-height: $line-height-base-default
) {
    &#app, body, p {
        @include font-styles($color, $font-size, $font-weight, $line-height);
        @content;
    }
}

@mixin h1(
    $color: $text-color-default,
    $font-size: $font-size-largest-default,
    $font-weight: $font-weight-medium-default,
    $line-height: $line-height-base-default
) {
    h1 {
        @include font-styles($color, $font-size, $font-weight, $line-height);
        @content;
    }
}

@mixin h2(
    $color: $text-color-default,
    $font-size: $font-size-larger-default,
    $font-weight: $font-weight-medium-default,
    $line-height: $line-height-base-default
) {
    h2 {
        @include font-styles($color, $font-size, $font-weight, $line-height);
        @content;
    }
}

@mixin h3(
    $color: $text-color-default,
    $font-size: $font-size-large-default,
    $font-weight: $font-weight-medium-default,
    $line-height: $line-height-base-default
) {
    h3 {
        @include font-styles($color, $font-size, $font-weight, $line-height);
        @content;
    }
}

@mixin h4(
    $color: $text-color-default,
    $font-size: $font-size-base-default,
    $font-weight: $font-weight-medium-default,
    $line-height: $line-height-base-default
) {
    h4 {
        @include font-styles($color, $font-size, $font-weight, $line-height);
        @content;
    }
}

@mixin bg-img($path-prefix, $url, $height, $background-color: transparent) {
    // see also bg-img class in common
    .bg-img {
        background-image: url("/frontend/assets/images/" + $path-prefix + $url);
        background-color: $background-color;
        height: $height;
        @content;
    }
}

@mixin responsive-bg-img($path-prefix, $url, $height-to-width-ratio, $background-color: transparent) {
    // remember to always use bg-img class with .responsive-bg-img!
    .responsive-bg-img {
        background-image: url("/frontend/assets/images/" + $path-prefix + $url);
        background-color: $background-color;
        height: 0;
        padding-top: percentage($height-to-width-ratio);
        @content;
    }
}

@mixin table($stripe-bg-color, $row-border) {
    .table {
        &.table-striped > tbody > tr:nth-of-type(odd) {
            background-color: $stripe-bg-color;
        }

        &> tbody > tr > td {
            border: $row-border;
        }
    }
}


// Respond-to
@mixin respond-to($media) {
    @if $media == navbar-collapsed { @media only screen and (max-width: $grid-float-breakpoint - 1) { @content; } }

    @if $media == very-narrow-screen { @media only screen and (max-width: 430px) { @content; } }

    @if $media == screen-below-sm { @media only screen and (max-width: $screen-sm-min) { @content; } }
    @if $media == screen-sm { @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; } }

    @if $media == screen-below-md { @media only screen and (max-width: $screen-md-min) { @content; } }
    @if $media == screen-md { @media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; } }

    @if $media == screen-below-lg { @media only screen and (max-width: $screen-lg-min) { @content; } }
    @if $media == screen-lg { @media only screen and (min-width: $screen-lg-min) { @content; } }
}

// flexbox mixins
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -webkit-flex:  $values;
    -ms-flex:  $values;
    flex:  $values;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -moz-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin flex-direction($value: row) {
    @if $value == row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
        -moz-box-direction: reverse;
        -moz-box-orient: horizontal;
    } @else if $value == column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
    } @else if $value == column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
        -moz-box-direction: reverse;
        -moz-box-orient: vertical;
    } @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
        -moz-box-direction: normal;
        -moz-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -moz-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -moz-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    align-items: $value;
}

@mixin flex-wrap($value: nowrap) {
    // No Webkit/FF Box fallback.
    -webkit-flex-wrap: $value;
    @if $value == nowrap {
        -ms-flex-wrap: none;
    } @else {
        -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

@mixin placeholder-styles($color, $text-align: left) {
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $color !important;
        text-align: $text-align;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color !important;
        opacity:  1;
        text-align: $text-align;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color !important;
        opacity:  1;
        text-align: $text-align;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $color !important;
        text-align: $text-align;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $color !important;
        text-align: $text-align;
    }
    ::placeholder { /* Most modern browsers support this now. */
        color: $color !important;
        text-align: $text-align;
    }
}