@font-face {
    font-family: "Inter";
    src: url('/frontend/assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url('/frontend/assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url('/frontend/assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
