// Login page is the same for every area
#login {
    @include h1($text-color-default, $font-size-xlarge-default, $font-weight-bold-default, $line-height-h1-default);
    @include h2($text-color-default, $font-size-largest-default, $font-weight-bold-default, $line-height-h2-default);
    @include h3($text-color-default, $font-size-large-default, $font-weight-bold-default, $line-height-h3-default);
    .subheading {
        @include font-styles($text-color-default, $font-size-larger-default, $font-weight-medium-default, $line-height-subheading-default);
    }


    .part-1 {
        padding: 42px 0 48px 0;
        background: linear-gradient(264.77deg, $variant-secondary-future-darkblue 1.39%, #7E4FB1 98.78%);
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;

        h1, h2 {
            color: $white;
        }

        h2 {
            @include font-styles($white, $font-size-larger-default, $font-weight-medium-default, $line-height-subheading-default);
            margin-top: 15px;
            max-width: 500px;
            @include respond-to(screen-below-md){
                margin-left: auto;
                margin-right: auto;
            }
        }

        .illustration {
            min-height: 460px;
            background-image: url("/frontend/assets/images/akademia/login_illustration.png");
            background-position: top 0 right -50px;
            background-repeat: no-repeat;
            background-size: auto;
            padding: 80px 0 0 50px;
        }

        @include respond-to(screen-md) {
            .illustration {
                padding: 60px 0 0 0px;
                background-position: top 0 right -75px;
            }
        }

        @include respond-to(screen-below-md) {
            padding: 22px 0px 48px 0px;
            text-align: center;

            .illustration {
                background-position: top center;
                padding: 460px 20px 0 20px;
            }
        }
    }

    .part-2 {
        padding: 90px 10px;
        background-color: $variant-secondary-light-blue;

        h2 {
            text-align: center;
            color: $variant-dark-blue-login;
            padding-bottom: 15px;
        }

        .subheading {
            text-align: center;
            color: $variant-dark-blue-login;
            padding-bottom: 70px;
        }

        .thumbnail {
            background-color: $variant-secondary-light-blue;

            h3 {
                text-align: center;
                color: $variant-dark-blue-login;
                padding: 20px 0 20px 0px;
            }

            p {
                text-align: center;
                @include font-styles($text-color-default, $font-size-base, $font-weight-regular-default, $line-height-body-default);

            }

            .img {
                min-height: 150px;
            }
        }
    }

    .part-3 {
        padding: 90px 20px;
        background-color: $variant-dark-blue-login;

        h2 {
            color: $white;
            text-align: center;
            margin-bottom: 20px;
        }

        h3 {
            @include font-styles($white, $font-size-larger-default, $font-weight-medium-default, $line-height-subheading-default);
            text-align: center;
            margin-bottom: 60px;
        }

        .sso-btn-wrapper {
            width: 100%;
            text-align: center;
        }

        // @note: these are not used in this variant, only SSO login button
        .panel {
            padding: 30px 15px;
            @include respond-to(very-narrow-screen) { padding: 20px 5px; }

            a {
                @include font-styles($variant-primary-dark-blue, $font-size-base-default, $font-weight-medium-default);
                text-decoration: underline;
                cursor: pointer;
            }

            .forgotten-pass-link-wrapper {
                width: 100%;
                text-align: left;
                @include respond-to(very-narrow-screen) { text-align: center;}
            }

            .submit-btn-wrapper {
                margin-top: 20px;
                text-align: right;
                @include respond-to(very-narrow-screen) { text-align: center;}
            }

            .panel-footer {
                padding-top: 30px;
                border-top: 1px solid #d2e1ea;
                text-align: center;

                .btn {
                    min-width: 165px;
                }
            }
        }
    }
}
