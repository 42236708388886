#leaderboard {

    .part-1 {
        padding: 60px 20px;
        h1, h3 { text-align: center; }

        .rank-section {
            text-align: center;
            margin-top: 60px;

            .bg-img {
                margin-bottom: 15px;
            }

            h2 {
                margin: 15px 0;
                line-height: 1;
            }

            .rank {
                color: $text-color-default;
            }

            .btn {
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 40px;
            }
        }

        .ladder-section {
            margin-top: 60px;

            h2 {
                text-align: center;
                margin-bottom: 20px;
            }

            .leaderboard-table {
                tr>td:first-child {
                    padding-left: 30px;
                }

                tr>td:last-child {
                    text-align: right;
                    padding-right: 30px;
                }
                tr>td.text-center:last-child {
                    text-align: center;
                    padding-right: 8px;
                }

                .user-row {
                    font-weight: $font-weight-medium-default;
                }
            }
        }
    }
}