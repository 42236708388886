#item-dashboard {

    .part-1 {
        padding: 50px 20px 50px 20px;

        .user-progress {
            margin-bottom: 0px;
        }

        // added in 12/2022 - enabled by feature flag in config
        .category-info {
            margin: 0;
            padding: 0px 10px;
            min-height: 80px;

            .category-info-title-row {
                @include flexbox();
                @include align-items(center);
                @include respond-to(screen-below-sm) { display: block !important; }
            }

            .img {
                max-height: 50px;
                margin-left: 0;
            }

            h3 { 
                color: $variant-primary-dark-blue;
                font-size: 16px;
            }
            h2 {
                color: $variant-secondary-dark-blue;
                font-size: 20px;
            }

            .category-info-btn-col {
                text-align: right;
                @include respond-to(screen-below-sm) {
                    text-align: center;
                    margin-top: 10px;
                }

                a {
                    cursor: pointer;
                    font-size: 16px;
                }
            }

            .category-info-detail {
                padding-top: 10px;

                a {
                    text-decoration: underline;
                }

                .info-content {
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }

        .item-view-row {
            padding-top: 20px;
            padding-bottom: 30px;

            .item-view-wrapper {

                .panel-body {
                    border: 2px solid $variant-secondary-dark-blue;
                    min-height: 300px;

                    h3 {
                        color: $variant-secondary-dark-blue;
                        line-height: 1;
                        padding-bottom: 5px;
                        
                        @include respond-to(screen-below-sm) {
                            padding-top: 15px;
                        }
                    }

                    .onboarding-link-wrapper {
                        text-align: center;
                        @include font-styles($variant-primary-dark-blue, $font-size-base-default, $font-weight-medium-default);
                    }

                    .onboarding-link-wrapper a {
                        @include font-styles($variant-primary-dark-blue, $font-size-base-default, $font-weight-medium-default);
                        text-decoration: underline;
                    }

                    .points-wrapper, .item-category-wrapper {
                        color: $brand-8;
                        margin-top: 5px;
                        line-height: 1;
                        @include respond-to(screen-below-sm) {
                            text-align: center
                        }
                    ;
                    }

                    .points-wrapper, .area-title {
                        font-weight: $font-weight-medium-default;
                    }

                    .item-description {
                        padding: 12px 0 20px 0;
                        font-weight: $font-weight-medium-default;
                    }

                    .description-img {
                        max-height: 150px;
                        max-width: 400px;
                        margin-bottom: 20px;
                    }

                    .answer-btn-row {
                        padding-top: 5px;

                        .btn-col {
                            padding-top: 15px;
                            @include respond-to(screen-below-sm) {
                                text-align: center
                            }
                            .btn {
                                @include respond-to(very-narrow-screen) {
                                    padding: 13px 25px;
                                }
                                width: 100%;
                            }
                        }
                    }

                    .focus-row {
                        color: $brand-8;
                        padding-top: 20px;

                        .focus-title {
                            font-weight: $font-weight-medium-default;
                        }

                        a.focus-link {
                            @include font-styles($variant-primary-dark-blue, $font-size-base-default, $font-weight-medium-default);
                            text-decoration: underline;
                            padding-left: 5px;
                        }
                    }

                    .answer-response-view {
                        text-align: center;
                        padding: 10px 0 30px 0;

                        .bg-img {
                            margin: 30px 0;
                        }
                        p {
                            font-weight: $font-weight-medium-default;
                            padding: 0 40px;
                        }
                        .btn {
                            margin-top: 50px;
                        }

                        &.correct-answer {
                            h3 {
                                color: $variant-primary-green;
                            }
                            .points {
                                color: $variant-primary-green;
                            }
                        }

                        &.incorrect-answer {
                            h3 {
                                color: $variant-primary-red;
                            }
                            .points {
                                color: $variant-primary-red;
                            }
                            .answer-title {
                                padding-top: 15px;
                                padding-bottom: 5px;
                                color: $variant-primary-green;
                            }
                            .custom-checkbox {
                                label {
                                    cursor: default !important;
                                }
                                .tag-col {
                                    text-align: left !important;
                                }
                            }

                        }
                    }

                    .no-items-view {
                        text-align: center;
                        padding: 10px 0 30px 0;

                        h3 {
                            color: $variant-secondary-dark-blue;
                        }

                        .bg-img {
                            margin: 30px 0;
                        }

                        p {
                            padding: 0 40px;

                            &.no-items-description {
                                font-weight: $font-weight-medium-default;
                                padding-bottom: 10px;
                            }
                        }

                        .btn {
                            margin-top: 50px;
                            white-space: normal;
                            word-wrap: break-word;
                        }
                    }
                }
            }

            .explanation-col {
                padding: 10px 30px 0;

                h2 {
                    text-align: center;
                    line-height: 1;
                    padding-top: 10px;
                }

                h3 {
                    line-height: 1;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                a {
                    text-decoration: underline;
                    font-weight: $font-weight-medium-default;
                }
            }
        }
    }

    .part-2 {
        padding: 100px 20px;

        h2 {
            text-align: center;
            margin-bottom: 30px;
        }

        .btn-row {
            text-align: center;
            margin-top: 40px;
        }
    }
}
